import { Button } from "@/components/ui/button";
import {
  Menu,
  MenuCollection,
  MenuHeader,
  MenuItem,
  MenuPopover,
  MenuSection,
  MenuSeparator,
  MenuTrigger,
} from "@/components/ui/menu";
import { cn } from "@/lib/utils";
import { CaretDown, type Icon, Plus } from "@phosphor-icons/react";
import { memo } from "react";
import { Separator } from "../controls/separator";

interface DropdownOption {
  id: string; // Added id for unique key
  label: string;
  icon: Icon;
  onClick: () => void;
}

interface DropdownSection {
  id: string; // Added id for unique key
  label: string;
  options: DropdownOption[];
}

interface SplitButtonDropdownProps {
  buttonText: string;
  sections: DropdownSection[];
}

export const SplitButtonDropdown = memo(function SplitButtonDropdown({
  buttonText,
  sections,
}: SplitButtonDropdownProps) {
  return (
    <MenuTrigger>
      <Button className="px-3">
        <Plus className="mr-2" size={16} />
        <span className="mr-1">{buttonText}</span>
        <Separator orientation="vertical" className="mx-2 bg-white/30" />
        <CaretDown className="h-4 w-4 opacity-95" />
      </Button>
      <MenuPopover placement="bottom end" className="mr-2.5">
        <Menu>
          {sections.map((section, index) => (
            <>
              <MenuSection
                key={section.id}
                className={cn(index === sections.length - 1 && "mb-2")}
              >
                <MenuHeader separator={false}>{section.label}</MenuHeader>
                <MenuCollection items={section.options}>
                  {(option) => {
                    const IconComponent = option.icon;
                    return (
                      <MenuItem
                        key={option.id}
                        onAction={option.onClick}
                        className={"cursor-pointer"}
                      >
                        <IconComponent className="mr-1 h-4 w-4 text-gray-500" />
                        <span>{option.label}</span>
                      </MenuItem>
                    );
                  }}
                </MenuCollection>
              </MenuSection>
              {index !== sections.length - 1 && (
                <MenuSeparator className="mx-1.5" />
              )}
            </>
          ))}
        </Menu>
      </MenuPopover>
    </MenuTrigger>
  );
});
