// Tremor Raw chartColors [v0.1.0]

export type ColorUtility = "bg" | "stroke" | "fill" | "text" | "from" | "to";

export type SolidColorUtility = Exclude<ColorUtility, "from" | "to">;
export type GradientColorUtility = "from" | "to";

export const chartColors = {
  blue: {
    bg: "bg-blue-500",
    stroke: "stroke-blue-500",
    fill: "fill-blue-500",
    text: "text-blue-500",
    from: "from-blue-400",
    to: "to-blue-600",
  },
  orange: {
    bg: "bg-orange-500",
    stroke: "stroke-orange-500",
    fill: "fill-orange-500",
    text: "text-orange-500",
    from: "from-orange-400",
    to: "to-orange-600",
  },
  violet: {
    bg: "bg-violet-500 rounded-lg",
    stroke: "stroke-violet-500",
    fill: "fill-violet-500",
    text: "text-violet-500",
    from: "from-violet-650",
    to: "to-violet-750",
  },
  green: {
    bg: "bg-green-500",
    stroke: "stroke-green-500",
    fill: "fill-green-500",
    text: "text-green-500",
    from: "from-green-400",
    to: "to-green-600",
  },
  gray: {
    bg: "bg-gray-500",
    stroke: "stroke-gray-500",
    fill: "fill-gray-500",
    text: "text-gray-500",
    from: "from-gray-400",
    to: "to-gray-600",
  },
  teal: {
    bg: "bg-teal-500",
    stroke: "stroke-teal-500",
    fill: "fill-teal-500",
    text: "text-teal-500",
    from: "from-teal-400",
    to: "to-teal-600",
  },
  rose: {
    bg: "bg-rose-500",
    stroke: "stroke-rose-500",
    fill: "fill-rose-500",
    text: "text-rose-500",
    from: "from-rose-400",
    to: "to-rose-600",
  },
  yellow: {
    bg: "bg-yellow-500",
    stroke: "stroke-yellow-500",
    fill: "fill-yellow-500",
    text: "text-yellow-500",
    from: "from-yellow-400",
    to: "to-yellow-600",
  },
  fuchsia: {
    bg: "bg-fuchsia-500",
    stroke: "stroke-fuchsia-500",
    fill: "fill-fuchsia-500",
    text: "text-fuchsia-500",
    from: "from-fuchsia-400",
    to: "to-fuchsia-600",
  },
} as const satisfies {
  [color: string]: {
    [key in ColorUtility]: string;
  };
};

export const chartGradientColors = {
  blue: {
    id: "blueGradient",
    stops: [
      { offset: "0%", color: "oklch(97% 0.0152 257.6)" },
      { offset: "100%", color: "oklch(40.75% 0.184 257.6)" },
    ],
  },
  orange: {
    id: "orangeGradient",
    stops: [
      { offset: "0%", color: "oklch(63.05% 0.2 41.49)" },
      { offset: "100%", color: "oklch(27% 0.0886 41.49)" },
    ],
  },
  violet: {
    id: "violetGradient",
    stops: [
      { offset: "0%", color: "oklch(61.88% 0.2774 314.44)" },
      { offset: "100%", color: "oklch(51.53% 0.2446 313.62)" },
    ],
  },
  green: {
    id: "greenGradient",
    stops: [
      { offset: "0%", color: "oklch(97% 0.049 149.18)" },
      { offset: "100%", color: "oklch(41.75% 0.1251 149.18)" },
    ],
  },
  gray: {
    id: "grayGradient",
    stops: [
      { offset: "0%", color: "oklch(98% 0.0067 286.07)" },
      { offset: "100%", color: "oklch(21% 0.0067 286.07)" },
    ],
  },
  teal: {
    id: "tealGradient",
    stops: [
      { offset: "0%", color: "oklch(97% 0.0277 210.83)" },
      { offset: "100%", color: "oklch(27% 0.062 210.83)" },
    ],
  },
  rose: {
    id: "roseGradient",
    stops: [
      { offset: "0%", color: "oklch(97% 0.0186 27.16)" },
      { offset: "100%", color: "oklch(27% 0.1237 27.16)" },
    ],
  },
  yellow: {
    id: "yellowGradient",
    stops: [
      { offset: "0%", color: "oklch(97% 0.0442 89.137)" },
      { offset: "100%", color: "oklch(27% 0.0635 89.137)" },
    ],
  },
  fuchsia: {
    id: "fuchsiaGradient",
    stops: [
      { offset: "0%", color: "oklch(97% 0.0234 317.04)" },
      { offset: "100%", color: "oklch(27% 0.1409 317.04)" },
    ],
  },
};

export type AvailableChartGradientsKeys = keyof typeof chartGradientColors;

export const getGradientId = (color: AvailableChartGradientsKeys) =>
  chartGradientColors[color]?.id ?? "grayGradient";

export type AvailableChartColorsKeys = keyof typeof chartColors;

export const AvailableChartColors: AvailableChartColorsKeys[] = Object.keys(
  chartColors,
) as Array<AvailableChartColorsKeys>;

export const constructCategoryColors = (
  categories: string[],
  colors: AvailableChartColorsKeys[],
): Map<string, AvailableChartColorsKeys> => {
  const categoryColors = new Map<string, AvailableChartColorsKeys>();
  categories.forEach((category, index) => {
    categoryColors.set(category, colors[index % colors.length] ?? "gray");
  });
  return categoryColors;
};

export const getColorClassName = (
  color: AvailableChartColorsKeys,
  type: ColorUtility,
  mode: "solid" | "gradient" = "solid",
): string => {
  if (mode === "gradient") {
    // Return gradient-specific classes
    return `${chartColors[color]?.from ?? "from-gray-500"} ${
      chartColors[color]?.to ?? "to-gray-500"
    } bg-gradient-to-br`;
  }

  const fallbackColor: Record<SolidColorUtility, string> = {
    bg: "bg-gray-500",
    stroke: "stroke-gray-500",
    fill: "fill-gray-500",
    text: "text-gray-500",
  };

  return (
    chartColors[color]?.[type as SolidColorUtility] ??
    fallbackColor[type as SolidColorUtility]
  );
};

// Tremor Raw getYAxisDomain [v0.0.0]

export const getYAxisDomain = (
  autoMinValue: boolean,
  minValue: number | undefined,
  maxValue: number | undefined,
) => {
  const minDomain = autoMinValue ? "auto" : (minValue ?? 0);
  const maxDomain = maxValue ?? "auto";
  return [minDomain, maxDomain];
};

// Tremor Raw hasOnlyOneValueForKey [v0.1.0]

export function hasOnlyOneValueForKey(
  array: any[],
  keyToCheck: string,
): boolean {
  const val: any[] = [];

  for (const obj of array) {
    if (Object.prototype.hasOwnProperty.call(obj, keyToCheck)) {
      val.push(obj[keyToCheck]);
      if (val.length > 1) {
        return false;
      }
    }
  }

  return true;
}
