import type * as LabelPrimitive from "@radix-ui/react-label";
import { Slot } from "@radix-ui/react-slot";
import * as React from "react";
import {
  Controller,
  type ControllerProps,
  type FieldPath,
  type FieldValues,
  FormProvider,
  useFormContext,
} from "react-hook-form";

import { Label } from "@/components/ui/forms/label";
import { cn } from "@/lib/utils";

/**
 * @deprecated Use the new ui/field component instead
 */
const Form = FormProvider;

/**
 * @deprecated Use the new ui/field component instead
 */
type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  name: TName;
};

/**
 * @deprecated Use the new ui/field component instead
 */
const FormFieldContext = React.createContext<FormFieldContextValue>(
  {} as FormFieldContextValue,
);

/**
 * @deprecated Use the new ui/field component instead
 */
const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

/**
 * @deprecated Use the new ui/field component instead
 */
const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();

  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>");
  }

  const fieldState = getFieldState(fieldContext.name, formState);
  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  };
};

/**
 * @deprecated Use the new ui/field component instead
 */
type FormItemContextValue = {
  id: string;
};

/**
 * @deprecated Use the new ui/field component instead
 */
const FormItemContext = React.createContext<FormItemContextValue>(
  {} as FormItemContextValue,
);

/**
 * @deprecated Use the new ui/field component instead
 */
const FormItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const id = React.useId();

  return (
    <FormItemContext.Provider value={{ id }}>
      <div ref={ref} className={cn("pt-3", className)} {...props} />
    </FormItemContext.Provider>
  );
});
FormItem.displayName = "FormItem";

// Define common styles for form elements
const baseLabelClass = "font-medium font-[Inter] text-slate-800";
const errorLabelClass = "text-rose-600 dark:text-rose-400";
const baseDescriptionClass = "text-sm text-slate-500 dark:text-slate-400";
const baseMessageClass = "text-sm font-medium";

/**
 * @deprecated Use the new ui/field component instead
 */
const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & {
    required?: boolean;
    optional?: boolean;
  }
>(({ className, optional, required, ...props }, ref) => {
  const { error, formItemId } = useFormField();

  return (
    <Label
      ref={ref}
      className={cn(baseLabelClass, error && errorLabelClass, className)}
      htmlFor={formItemId}
      {...props}
    >
      {props.children}
      {required && <span className="text-rose-700"> *</span>}
      {optional && <span className="text-slate-500"> (optional)</span>}
    </Label>
  );
});
FormLabel.displayName = "FormLabel";

/**
 * @deprecated Use the new ui/field component instead
 */
const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } =
    useFormField();

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={
        !error ? formDescriptionId : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={!!error}
      {...props}
    />
  );
});
FormControl.displayName = "FormControl";

/**
 * @deprecated Use the new ui/field component instead
 */
const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField();

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={cn(baseDescriptionClass, className)}
      {...props}
    />
  );
});
FormDescription.displayName = "FormDescription";

/**
 * @deprecated Use the new ui/field component instead
 */
const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error?.message) : children;

  const invisibleMessageClass = "invisible overflow-hidden";

  return (
    <p
      ref={ref}
      id={formMessageId}
      className={cn(
        baseMessageClass,
        errorLabelClass,
        !body && invisibleMessageClass,
        className,
      )}
      {...props}
    >
      {body || "\u00A0"} {/* Non-breaking space to preserve layout */}
    </p>
  );
});
FormMessage.displayName = "FormMessage";

export {
  useFormField,
  Form,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
  FormField,
};
