import FacebookIcon from "@/assets/svg/facebook.svg?react";
import InstagramIcon from "@/assets/svg/instagram.svg?react";
import LinkedInIcon from "@/assets/svg/linkedin.svg?react";

interface AdChannelProps {
  channel: string | undefined;
}

const AdChannel: React.FC<AdChannelProps> = ({ channel }) => {
  return (
    <>
      {channel && (
        <div key={channel} className="flex items-center">
          {channel === "linkedin" && <LinkedInIcon className="me-1" />}
          {channel === "facebook" && <FacebookIcon className="me-1" />}
          {channel === "instagram" && <InstagramIcon className="me-1" />}
        </div>
      )}
    </>
  );
};

export default AdChannel;
