import { hexToHsva, hsvaToHex } from "@uiw/color-convert";
import type { HsvaColor } from "@uiw/color-convert";
import Chrome from "@uiw/react-color-chrome";
import { GithubPlacement } from "@uiw/react-color-github";
import { useState } from "react";
import { Button } from "../controls/button";
import { Popover, PopoverContent, PopoverTrigger } from "../navigation/popover";

type ColorPickerProps = {
  color: string;
  onChange: (color: string) => void;
};

export const ColorPicker = ({ color, onChange }: ColorPickerProps) => {
  const [hsva, setHsva] = useState<HsvaColor>(() => hexToHsva(color));

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" className="gap-2">
          <div
            className="w-4 h-4 rounded-full"
            style={{ backgroundColor: color }}
          >
            &nbsp;
          </div>
          {color}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="bg-transparent border-none p-0 shadow-none">
        <Chrome
          color={hsva}
          onChange={(color) => {
            setHsva(color.hsva);
            onChange(hsvaToHex(color.hsva));
          }}
          placement={GithubPlacement.Top}
          showAlpha={false}
        />
      </PopoverContent>
    </Popover>
  );
};
