import * as React from "react";

import { cn } from "@/lib/utils";

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

/**
 * @deprecated Use the new ui/textfield component instead
 */
const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          "flex min-h-[80px] w-full rounded-md border",
          "border-slate-300 bg-white px-3 py-2 text-sm",
          "ring-offset-white placeholder:text-slate-500",
          "focus-visible:outline-none focus-visible:ring-1",
          "focus-visible:ring-blue-750",
          "disabled:opacity-50",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Textarea.displayName = "Textarea";

export { Textarea };
