import { Plus, X } from "@phosphor-icons/react";

import { Button } from "@/components/ui/controls/button";
import { Command, CommandGroup } from "@/components/ui/controls/command";
import { Badge } from "@/components/ui/data-display/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/data-display/tooltip";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/navigation/popover";
import { memo } from "react";
import { Tabs, TabsList, TabsTrigger } from "../navigation/tabs";

interface FilterPillProps {
  title?: string;
  selectedOptions?: string[];
  setSelectedOptions: (options: string[] | undefined) => void;
  relationship?: "AND" | "OR" | "OR-ONLY";
  advanced?: boolean;
  setUpdateRelationships?: (value: "AND" | "OR") => void;
}

function FilterPill({
  title,
  selectedOptions,
  setSelectedOptions,
  relationship,
  advanced = false,
  setUpdateRelationships,
}: FilterPillProps) {
  const selectedValues = new Set(selectedOptions);

  const renderBadge = () => {
    const selectedArray = Array.from(selectedValues);
    if (selectedArray.length > 3) {
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Badge
                status={false}
                className="rounded-sm px-0.5 font-normal bg-white"
              >
                {selectedArray.length} selected
              </Badge>
            </TooltipTrigger>
            <TooltipContent className="max-w-md text-wrap z-40">
              {selectedArray
                .map((value) => {
                  const option = selectedOptions?.find(
                    (option) => option === value,
                  );
                  return option || value; // Fallback to value if option not found
                })
                .filter(Boolean) // Remove null values
                .join(", ")}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }

    return selectedArray.map((value, index, array) => {
      const option = selectedOptions?.find((option) => option === value);
      if (!option) return null;

      const content = <span className="first-letter:uppercase">{option}</span>;
      const conjunction = relationship === "AND" ? "and" : "or";

      return (
        <span
          className="first-letter:uppercase whitespace-nowrap inline-flex items-center"
          key={option}
        >
          {content}
          {advanced && index < array.length - 1 && (
            <span>
              {array.length === 2 ? (
                <>&nbsp;{conjunction}</>
              ) : index === array.length - 2 ? (
                `, ${conjunction}`
              ) : (
                ","
              )}
            </span>
          )}
          {!advanced && index < array.length - 1 && <span>&nbsp;</span>}
        </span>
      );
    });
  };

  if (selectedValues.size === 0) return null;

  return (
    <div className="relative mr-2">
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            className="h-7 pr-0.5 rounded-3xl border border-slate-100 text-slate-800 text-xs pl-2"
          >
            {selectedValues.size === 0 && (
              <Plus className="mr-1 h-4 w-4 text-slate-500" />
            )}
            <span className="pr-1 font-normal">
              {title}
              {selectedValues.size > 0 && ":"}
            </span>

            {selectedValues.size > 0 && (
              <>
                <Badge
                  status={false}
                  className="rounded-sm px-1 font-normal lg:hidden"
                >
                  {selectedValues.size}
                </Badge>
                <div className="hidden space-x-1 lg:flex pr-6 mr-2">
                  {renderBadge()}
                </div>
              </>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="p-0 bg-white rounded-md shadow-md"
          sideOffset={5}
          align="start"
        >
          <Command>
            <CommandGroup>
              {advanced && (
                <>
                  {/* <Separator className="my-1.5" /> */}
                  <div className="flex flex-col text-sm pl-2 gap-1 pb-1">
                    <div className="flex flex-row justify-between">
                      <span className="self-center">Must Match</span>
                      <Tabs
                        defaultValue={relationship}
                        onValueChange={(val) => {
                          if (val === "AND" || val === "OR")
                            setUpdateRelationships?.(val);
                        }}
                      >
                        <TabsList
                          className="grid w-full grid-cols-2"
                          variant="small"
                        >
                          <TabsTrigger variant="small" value="AND">
                            All
                          </TabsTrigger>
                          <TabsTrigger variant="small" value="OR">
                            Any
                          </TabsTrigger>
                        </TabsList>
                      </Tabs>
                    </div>
                  </div>
                </>
              )}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
      {selectedOptions && selectedOptions.length > 0 && (
        <Button
          size="xs"
          variant="ghost"
          onClick={(e) => {
            e.stopPropagation();
            setSelectedOptions(undefined);
          }}
          className="px-0 pr-2 text-slate-600 hover:text-slate-900 transition duration-300 absolute right-0 rounded-r-full ml-1"
        >
          <X
            className="ml-2  text-slate-600 hover:text-slate-900 transition duration-300"
            size={14}
          />
        </Button>
      )}
    </div>
  );
}

export default memo(FilterPill);
